
export function enrollOptions() {
  return {
    xAxis: {
      type: "category",
      data: ["第一届", "第二届", "第三届", "第四届", "高校一届", "高校二届"],
      axisLine: {
        lineStyle: {
          color: "#fff", // 设置坐标轴颜色
        },
      },
      axisLabel: {
        rotate: 45, // 设置标签倾斜角度，单位为度
      },
      color: "#fff",
    },
    tooltip:{
        trigger:'axis'
    },
    yAxis: {
      type: "value",
      axisLine: {
        lineStyle: {
          color: "#fff", // 设置坐标轴颜色
        },
      },
      name: "队数（个）",
      nameTextStyle: {
        color: "#aaa",
        nameLocation: "start",
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: ["#e7e7e7"],
          width: 1,
          type: "dotted",
        },
      },
    },
    series: [
      {
        color: "#62f8fa",
        data: [8, 16, 32, 64, 8, 16],
        type: "bar",
        barWidth: "80%",
        showBackground: true,
        backgroundStyle: {
          color: "rgba(180, 180, 180, 0.2)",
        },
      },
    ],
  };
}
// 监管者
export function hunterOptions() {
    return {
        tooltip:{
            trigger:'item'
        },
        radar: {
          // shape: 'circle',
          indicator: [
            { name: '歌剧演员', max: 100 },
            { name: '佣兵', max: 100 },
            { name: '守夜人', max: 100 },
            { name: '先知', max: 100 },
            { name: '疯眼', max: 100 },
            { name: '祭司', max: 100 }
          ]
        },
        series: [
          {
            name: 'Budget vs spending',
            type: 'radar',
            data: [
              {
                value: [78, 70, 90, 80, 70, 80],
                name: '监管者',
                areaStyle: {
                    color: '#ffdadd'
                },
                lineStyle:{
                    color: '#ffdadd'
                }
              
              },
              {
                value: [100, 50, 30, 20, 80, 50],
                name: '求生者',
                areaStyle: {
                    color: '#32dadd'
                },
                lineStyle:{
                    color: '#32dadd'
                }
              }
            ]
          }
        ]
      };
}

// 人员
export function personOptions(obj){
  const data = Object.values(obj)
  return {
    xAxis: {
      type: "category",
      data: ["表单数", "参赛人数", "赛程数", "战队数", "官网用户量"],
      axisLine: {
        lineStyle: {
          color: "#fff", // 设置坐标轴颜色
        },
      },
      axisLabel: {
        rotate: 45, // 设置标签倾斜角度，单位为度
      },
      color: "#fff",
    },
    tooltip:{
        trigger:'axis'
    },
    yAxis: {
      type: "value",
      axisLine: {
        lineStyle: {
          color: "#fff", // 设置坐标轴颜色
        },
      },
      name: "队数（个）",
      nameTextStyle: {
        color: "#aaa",
        nameLocation: "start",
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: ["#e7e7e7"],
          width: 1,
          type: "dotted",
        },
      },
    },
    series: [
      {
        color: "#62f8fa",
        data: data,
        type: "bar",
        barWidth: "80%",
        showBackground: true,
        backgroundStyle: {
          color: "rgba(180, 180, 180, 0.2)",
        },
      },
    ],
  };
}

// 赛季对比
export function seasonOptions(){
  return {
    tooltip: {
      trigger: 'item',
      formatter:function(params){
        return params.value +'次'
      }
    },
    legend: {
      orient: 'vertical',
      left: 'right',
      top:'bottom',
      textStyle:{
        color:'#fff'
      }
    },
    color: ['#fc8251', '#5470c6', '#91cd77'],
    series: [
      {
        name: '赛季图',
        type: 'pie',
        radius: '50%',
        data: [
          { value: 2, name: '高校赛' },
          { value: 5, name: '众创赛' },
          { value: 1, name: '神将赛' },
        ],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }
    ]
  };
}

// 人员构成
export function orgNameOptions(){
  return {
    xAxis: {
      type: "category",
      data: ["解说人员", "开发人员", "主办方", "赛事组成员", "导播", "裁判"],
      axisLine: {
        lineStyle: {
          color: "#fff", // 设置坐标轴颜色
        },
      },
      axisLabel: {
        rotate: 0, // 设置标签倾斜角度，单位为度
      },
      color: "#fff",
    },
    tooltip:{
        trigger:'axis'
    },
    yAxis: {
      type: "value",
      axisLine: {
        lineStyle: {
          color: "#fff", // 设置坐标轴颜色
        },
      },
      name: "人数（人）",
      nameTextStyle: {
        color: "#aaa",
        nameLocation: "start",
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: ["#e7e7e7"],
          width: 1,
          type: "dotted",
        },
      },
    },
    legend: {
      orient: 'vertical',
      left: 'right',
      top:'bottom',
      textStyle:{
        color:'#fff'
      }
    },
    series: [
      {
        color: "#62f8fa",
        data: [12, 4, 2, 18, 6, 8],
        type: "bar",
        barWidth: "40%",
        showBackground: true,
        backgroundStyle: {
          color: "rgba(180, 180, 180, 0.2)",
        },
      },
    ],
  };
}


