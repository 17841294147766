<template>
  <div class="big-wrap">
    <div class="container-wrap" @mouseenter="handleEnter" @mouseleave="handleLeave">
        <li ref="liRef" v-for="(item,index) in tableData" :key="index" @click = "routeTo">
           <i class="el-icon-message-solid"></i>{{item.title}}
        </li>
    </div>
  </div>
</template>

<script>
import { getNews } from "@/api/news/index";
export default {
  name: "announce",
  data() {
    return {
      tableData: [],
    };
  },
  async created() {
    const { data } = await getNews();
    this.tableData = data;
  },
  methods:{
    handleEnter(){
        this.$refs.liRef[0].style.animationPlayState = 'paused'
    },
    handleLeave(){
        this.$refs.liRef[0].style.animationPlayState = 'running'
    },
    routeTo(){
        this.$router.push('/index/news')
    }
  }
};
</script>

<style scoped lang="less">
.big-wrap {
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  padding: 10px;
}
.container-wrap {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
//   border: 1px solid #62f8fa;
  overflow: hidden;
  li{
    cursor:pointer;
    border-bottom: 1px solid #62f8fa;
    box-sizing: border-box;
    list-style: none;
    height: 40px;
    line-height:40px;
    color:#ddd;
    text-align: center;
    background-color:rgb(0, 24, 74);
    &:hover{
        background-color: rgb(52,41,122);
        color:#f7e157;
    }
    &:first-child{
        animation: move 10s infinite;
    }
  }
}
@keyframes move {
   0%{
    margin-top: 0;
   }
   16%{
    margin-top: -40px;
   }
   33%{
    margin-top:-80px;
   }
   48%{
    margin-top:-120px
   }
   64%{
    margin-top:-80px;
   }
   80%{
    margin-top:-40px;
   }
   100%{
    margin-top:0px;
   }
}
</style>
